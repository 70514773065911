import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Montgomery, AL',
  cityModal: true,

  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/wQh9ZX2Eivg',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Montgomery is the state capital of Alabama. A city deeply rooted in
      history, it was the birthplace of the Confederacy and the Civil Rights
      Movement and includes many landmarks to both periods.
    </p>
    <p>
      Montgomery’s population is becoming more diverse. The city’s white
      population has decreased by 30 percent since 2000, but it has experienced
      significant growth among communities of color, putting it on pace to
      become a city with a people of color majority. Montgomery elected its
      first black mayor in fall 2019. Six of every 10 jobs in Montgomery are
      held by workers living outside of the city limits, and there are wide gaps
      in earnings by race and gender. Both racial groups were largely negative
      about the city’s direction; they broadly agreed that the crux of
      interrelated city problems included low wages, crime, and challenged
      schools. When asked what unites residents, both groups cited workplaces,
      churches, public events (especially fairs and football), food, and
      responses to disaster. Both groups agreed that the best path forward
      starts with fixing the schools. There was also agreement on the need for
      higher wages and a stronger police department to tackle crime. In
      Montgomery, we conducted focus groups where we heard the opinions of
      non-college-educated black and white residents. Learn more by reading the
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
